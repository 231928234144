// Session.tsx

import React, { useState } from 'react';
import { useAsync, useAsyncCallback } from "react-async-hook";

import { Link } from 'react-router-dom';

import { ISearchInterface, IFacilityInterface, IMatchInterface } from '../../../@types/context';
import { SearchContext } from '../../context/SearchContext';
import { MatchContext } from '../../context/MatchContext';

// import '../../../styles.css'

import ServerService from '../../../services/server.service';
const serverService = new ServerService();

// Set to indicate production mode. No password is required for production
const PRODUCTION=true; // true; // true; // false;


let sequenceid: number = 1;

interface SessionSettings {
  username: string;
  password: string;
};

let prodlogincount = 0;

const Session: React.FC = () => {
  const emptySettings = {
    username: '',
    password: '',
  }

  const { sessionid, saveSession } = React.useContext(SearchContext) as ISearchInterface;
  const [currentSettings, setCurrentSettings] = useState<SessionSettings>(emptySettings);
  const { stateview } = React.useContext(MatchContext) as IMatchInterface;


  const onLogin = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }

  const updateUsername = async (evt: any) => {
    evt.preventDefault();
    const username = evt.target.value;

    const settings = {...currentSettings};
    settings.username = username;
    setCurrentSettings(settings);
  }
  const onusernameclick = useAsyncCallback(updateUsername);

  const updatePassword = async (evt: any) => {
    evt.preventDefault();
    const password = evt.target.value;

    const settings = {...currentSettings};
    settings.password = password;
    setCurrentSettings(settings);
  }
  const onpasswordclick = useAsyncCallback(updatePassword);

  const login = async () => {
    const body = JSON.stringify({
      username: currentSettings.username,
      password: currentSettings.password
    })
    const response = await serverService.post('user/session', body);
    if (response && response?.sessionid && response?.sessionid?.length > 0) {
      // UtilsService.consoledev('saving session');
      saveSession(response.sessionid);
    }

    // Clear the data
    const settings = {...currentSettings};
    settings.username = '';
    settings.password = '';
    setCurrentSettings(settings);    
  }
  const onloginclick = useAsyncCallback(login);

  // use prodlogincount to make only a single login request. Otherwise this page will keep re-rendering.
  //UtilsService.consoledev('production', PRODUCTION);
  if (PRODUCTION && prodlogincount == 0) {
    // Get the session id from the server and do not require a username/password
    currentSettings.username = Date.now().toString();
    currentSettings.password = '';
    setCurrentSettings(currentSettings);

    prodlogincount++;

    (async() => {
      await login();
    })();
  }

  if (PRODUCTION || (sessionid && sessionid.length > 0)) {
    return (
      null
    );
  }

  return (
    <div>
      <span>Username: </span>
      <input id="username" name="username" onChange={onusernameclick.execute}/>
      <span>Password: </span>
      <input type="password" id="password" name="password" onChange={onpasswordclick.execute}/>
      <button id="login" name="login" onClick={onloginclick.execute}>Login</button>
    </div>

  );
};

export default Session;